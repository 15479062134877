// CategoryDetails.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CardContent, Grid, Typography } from "@mui/material";
import { Card, CardMedia } from "@mui/material";
import { baseUrl } from "utils/baseUrl";

interface Item {
  id: string;
  name?: string;
  images?: string[];
  description?: string;
  quantity?: number;
  price?: number;
}

interface CategoryDetails {
  id: string;
  name: string;
  description: string;
  equipments: Item[];
  supplies: Item[];
}

const initialCategoryDetails: CategoryDetails = {
  id: "",
  name: "",
  description: "",
  equipments: [],
  supplies: [],
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export default function CategoryDetails() {
  const { id } = useParams<{ id: string }>();
  const [categoryDetails, setCategoryDetails] =
    useState<CategoryDetails | null>(initialCategoryDetails);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [relatedItems, setRelatedItems] = useState<Item[]>([]);
  const [relatedSupplies, setRelatedSupplies] = useState<Item[]>([]);
  // console.log("====================================");
  // console.log(categoryDetails);
  // console.log("====================================");

  // Fetch category details
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/categories/${id}`)
      .then((response) => {
        setCategoryDetails(response.data.category);
        setRelatedSupplies(response.data.category.supplies);
        setRelatedItems(response.data.category.equipments);
      })
      .catch((error: any) => {
        setError(error.response?.data?.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  // Fetch related items

  // ...

  // useEffect(() => {
  //   if (categoryDetails) {
  //     setLoading(true);
  //     setError("");

  //     const fetchEquipments = categoryDetails.equipments.map((equipment) =>
  //       axios
  //         .get(`${baseUrl}/equipments/${equipment}`)
  //         .then((response) => response.data.equipment)
  //         .catch((error) => {
  //           console.error(
  //             "Fetching equipment data failed for ID: " + equipment,
  //             error
  //           );
  //           return null;
  //         })
  //     );

  //     if (categoryDetails?.supplies.length > 0) {
  //       const fetchSupplies = categoryDetails.supplies.map((supply) =>
  //         axios
  //           .get(`${baseUrl}/supplies/${supply}`)
  //           .then((response) => response.data.supply)
  //           .catch((error) => {
  //             console.error(
  //               "Fetching supply data failed for ID: " + supply,
  //               error
  //             );
  //             return null;
  //           })
  //       );

  //       Promise.all(fetchSupplies)
  //         .then((suppliesData) => {
  //           // setRelatedSupplies(suppliesData.filter(Boolean)); // Update the supplies state
  //         })
  //         .catch((error) => {
  //           setError("An error occurred while fetching supplies details.");
  //           console.error(error);
  //         });
  //     }
  //     Promise.all([...fetchEquipments])
  //       .then((results) => {
  //         // فلترة النتائج لإزالة القيم الخالية وتقسيم البيانات إلى مجموعتين
  //         const equipmentsData = results
  //           .slice(0, fetchEquipments.length)
  //           .filter(Boolean);
  //         // const suppliesData = results
  //         //   .slice(fetchEquipments.length)
  //         //   .filter(Boolean);

  //         // حفظ البيانات في حالات مختلفة إذا كنت ترغب في ذلك
  //         // setRelatedItems(equipmentsData); // هذا سيكون للـ equipments فقط إذا كنت تريد دمج البيانات
  //         // setRelatedSupplies(suppliesData); // يمكنك إنشاء حالة جديدة لـ supplies إذا لزم الأمر
  //       })
  //       .catch((error) => {
  //         setError("An error occurred while fetching related items.");
  //         console.error(error);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [categoryDetails?.supplies, categoryDetails]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (
    !categoryDetails ||
    (categoryDetails.equipments.length === 0 &&
      categoryDetails.supplies.length === 0)
  ) {
    return <div>No category details available or no related items.</div>;
  }

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        key={categoryDetails.id}
        style={{
          maxWidth: "300px",
          textAlign: "center",
          // height: "100px",
          // margin: "10px",
        }}
      >
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {categoryDetails.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {categoryDetails.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <br />
      <hr />
      <h1>related Items for equipments </h1>
      {/* --------- */}
      {/* <hr /> */}
      <Grid
        container
        spacing={2}
        sx={{
          minWidth: "63vw",
          "& > .MuiGrid-item": {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minWidth: "300px",
          },
        }}
      >
        {relatedItems.length > 0 ? (
          relatedItems.map((item) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={item.id}
              style={{
                maxWidth: "300px",
                // height: "100px",
                // margin: "10px",
              }}
            >
              <Card>
                {/* <h4>images</h4> */}
                {/* {item.images && item.images.length > 0 ? (
                  item.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={item.name}
                      style={{
                        maxWidth: "100px",
                        height: "100px",
                        margin: "10px",
                      }}
                    />
                  ))
                ) : (
                  <p>No images to display.</p>
                )} */}
                <CardMedia
                  component="img"
                  height="140"
                  image={
                    item.images && item.images[0]
                      ? item.images[0]
                      : "default_image_path"
                  }
                  alt={item.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                  <Typography variant="body1">
                    {item.quantity} Units | ${item.price}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <p>No related items for equipments.</p>
        )}
      </Grid>
      <br />
      <hr />
      <hr />
      <hr />
      <div>
        <h1>related Items for supplies</h1>
        ---------
        {/* <hr /> */}
        <Grid
          container
          spacing={2}
          sx={{
            minWidth: "63vw",
            "& > .MuiGrid-item": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: "300px",
            },
          }}
        >
          {relatedSupplies.length > 0 ? (
            relatedSupplies.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={item.id}
                style={{
                  maxWidth: "300px",
                }}
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      item.images && item.images[0]
                        ? item.images[0]
                        : "default_image_path"
                    }
                    alt={item.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {item.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                    <Typography variant="body1">
                      {item.quantity} Units | ${item.price}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <p>No related items for supplies.</p>
          )}
        </Grid>
      </div>
    </>
  );
}
