import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Button,
  CssBaseline,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Container } from "@mui/material";

interface ReusableDialogProps {
  formTitle: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  formik?: any;
  isSubmitting?: boolean;
  saveButtonLabel: string;
  isEdit?: boolean;
  dialogMaxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  handleSubmit?: any;
  showButton?: any;
}

const ReusableDialog: React.FC<ReusableDialogProps> = ({
  formTitle,
  open,
  onClose,
  children,
  formik,
  isSubmitting,
  saveButtonLabel,
  isEdit,
  dialogMaxWidth = "sm",
  handleSubmit,
  showButton = true,
}) => {
  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
        color: (theme) => theme.palette.custom?.dialogColor,
      }}
    >
      <CssBaseline />

      <Dialog open={open} onClose={onClose} maxWidth={dialogMaxWidth} fullWidth>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{formTitle}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            component="form"
            onSubmit={formik?.handleSubmit}
            noValidate
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                flexWrap: "wrap",
              }}
            >
              {children}

              {showButton ? (
                <Button
                  // type={isEdit ? "button" : "submit"}
                  type={"submit"}
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                    color: (theme) => theme.palette.custom?.buttonColor,
                  }}
                  disabled={isSubmitting}
                >
                  {saveButtonLabel}
                </Button>
              ) : (
                ""
              )}

              {handleSubmit ? (
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                    color: (theme) => theme.palette.custom?.buttonColor,
                  }}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  {saveButtonLabel}
                </Button>
              ) : (
                ""
              )}

              <>
                {/* {isEdit === true ? (
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                      bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                      color: (theme) => theme.palette.custom?.buttonColor,
                    }}
                    // onClick={handleSaveChanges}
                    disabled={formik.isSubmitting}
                  >
                    {saveButtonLabel}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                      color: (theme) => theme.palette.custom?.buttonColor,
                    }}
                    disabled={isSubmitting}
                  >
                    {saveButtonLabel}
                  </Button>
                )} */}
              </>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ReusableDialog;
