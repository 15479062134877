import { useFormik } from "formik";
import { WarehouseTypes } from "../types/types";
import { AllowToNumberOnly } from "Components/AllowToNumberOnly/AllowToNumberOnly";
import FormFields from "../FormFields";
import validationSchema from "../validationSchema";
import useSendData from "Components/Axios/SendData";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import Loading from "Components/ErrorAndLoading/Loading";

interface AddWarehouseProps {
  warehouse?: WarehouseTypes;
  handleClose: () => void;
  open: boolean;
  // setWarehouses: React.Dispatch<React.SetStateAction<WarehouseTypes[]>>;
  onHide?: () => void;
  setWarehouses?: any;
}

// The EditWarehouse component
export default function EditWarehouse({
  warehouse,
  handleClose,
  setWarehouses,
  open,
}: AddWarehouseProps) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...warehouse,
      id: warehouse.id,
    },
    validationSchema,
    onSubmit: async (values) => {
      const submittedData = await sendData({
        endpoint: `warehouses/${warehouse.id}`,
        data: values,
        isJson: true,
        method: "put",
      });

      if (submittedData) {
        setWarehouses((prevWarehouses: any) =>
          prevWarehouses.map((warehouse: any) =>
            warehouse.id === submittedData.warehouse.id
              ? submittedData.warehouse
              : warehouse
          )
        );
        handleClose();
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
    AllowToNumberOnly(
      (name: string, value: any) => {
        formik.setFieldValue(name, value);
      },
      fieldName,
      decimal
    );

  return (
    <>
      <>
        <ErrorDisplay error={sendError} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        formTitle={"Update Warehouse"}
        saveButtonLabel={"Update Warehouse"}
      />
    </>
  );
}
