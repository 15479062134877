import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import { useEffect, useState } from "react";

import { Box, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridRenderCellParams } from "@mui/x-data-grid";
import ConfirmDelete from "Components/Axios/ConfirmDelete";
import FetchData from "Components/Axios/FetchData";
import ReusableTable from "Components/Table/Table";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddWarehouse from "./AddWarehouse/AddWarehouse";
import EditWarehouse from "./EditWarehouse/EditWarehouse";

interface Warehouse {
  id: string;
  name: string;
  phone: string;
  street: string;
  state: string;
  city: string;
  email: string;
  zipCode: string;
  warehouses?: any;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export default function Warehouse() {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | null>(
    null
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [warehouseToDelete, setWarehouseToDelete] = useState<Warehouse | null>(
    null
  );
  const [editingWarehouse, setEditingWarehouse] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();

  const { data, error: warehousesError, isLoading } = FetchData("warehouses");

  useEffect(() => {
    if (data) {
      if (Array.isArray(data)) {
        setWarehouses(data);
      } else {
        setError("Data is not in expected format");
      }
    }
  }, [data]);

  const handleOpenDialog = () => {
    setSelectedWarehouse(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedWarehouse(null);
  };

  const handleDeleteClick = (warehouseId: string) => {
    const warehouse = warehouses.find((wh) => wh.id === warehouseId);

    if (warehouse) {
      setWarehouseToDelete(warehouse);
      setDeleteDialogOpen(true);
    } else {
      toast.error("Warehouse not found.");
    }
  };

  const handleEdit = (warehouse: any) => {
    // console.log(warehouse);
    setEditingWarehouse({ id: warehouse.id, ...warehouse });
    setIsEditModalOpen(true);
  };

  const handleWarehouseClick = (id: any) => {
    navigate(`/warehouse/${id}`);
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    { field: "street", headerName: "Street", flex: 1 },
    { field: "state", headerName: "State", flex: 1 },
    { field: "city", headerName: "City", flex: 1 },
    { field: "email", headerName: "Email", flex: 1.3 },
    { field: "zipCode", headerName: "Zip Code", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton>
            <VisibilityIcon onClick={() => handleWarehouseClick(params.id)} />
          </IconButton>

          <IconButton>
            <EditIcon
              onClick={() => {
                handleEdit(params.row as Warehouse);
              }}
            />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.id as string)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  
  const filteredRows = warehouses
    .filter((warehouse) => {
      const searchLower = searchText.toLowerCase();
      return (
        warehouse.name?.toLowerCase().includes(searchLower) ||
        warehouse.phone?.toLowerCase().includes(searchLower) ||
        warehouse.email?.toLowerCase().includes(searchLower) ||
        warehouse.city?.toLowerCase().includes(searchLower) ||
        warehouse.street?.toLowerCase().includes(searchLower) ||
        warehouse.state?.toLowerCase().includes(searchLower)
      );
    })
    .map((warehouse) => ({
      id: warehouse.id,
      name: warehouse.name ?? "N/A",
      phone: warehouse.phone ?? "N/A",
      email: warehouse.email ?? "N/A",
      city: warehouse.city ?? "N/A",
      street: warehouse.street ?? "N/A",
      state: warehouse.state ?? "N/A",
      zipCode: warehouse.zipCode ?? "N/A",
    }));
  

  return (
    <Box className="">
      <Typography variant="h4" fontWeight="medium">
        Warehouses
      </Typography>
      <>
        <ErrorDisplay error={warehousesError || error} />
        {/* <Loading isLoading={isLoading} /> */}
      </>
      <ReusableTable
        searchText={searchText}
        onSearchChange={handleSearchChange}
        onAddButtonClick={handleOpenDialog}
        rows={filteredRows}
        columns={columns}
        loading={isLoading}
        addButtonName="Add New Warehouse"
      />

      <AddWarehouse
        handleClose={handleCloseDialog}
        open={openDialog}
        setWarehouses={setWarehouses}
      />

      {editingWarehouse && (
        <EditWarehouse
          open={isEditModalOpen}
          warehouse={editingWarehouse}
          handleClose={() => setIsEditModalOpen(false)}
          setWarehouses={setWarehouses}
        />
      )}

      {deleteDialogOpen && warehouseToDelete && (
        <ConfirmDelete
          itemName={warehouseToDelete.name}
          endpoint={`warehouses/${warehouseToDelete.id}`}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDeleted={() => {
            setWarehouses((prevDetails) =>
              prevDetails.filter(
                (s: Warehouse) => s.id !== warehouseToDelete.id
              )
            );
            setDeleteDialogOpen(false);
            setWarehouseToDelete(null);
          }}
        />
      )}
    </Box>
  );
}
