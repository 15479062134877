import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { styled } from "@mui/material/styles";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  TextField,
  Button,
  Container,
  Box,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "utils/baseUrl";
import { UserTypes } from "../types/types";
import { WarehouseTypes } from "pages/Warehouses/types/types";
// import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import CustomPhoneInput from "Components/CustomPhoneInput/CustomPhoneInput";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = [
//   "Oliver Hansen",
//   "Van Henry",
//   "April Tucker",
//   "Ralph Hubbard",
//   "Omar Alexander",
//   "Carlos Abbott",
//   "Miriam Wagner",
//   "Bradley Wilkerson",
//   "Virginia Andrews",
//   "Kelly Snyder",
// ];

type AddUserProps = {
  handleClose: () => void;
  setUsers: (value: React.SetStateAction<UserTypes[]>) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleError = (error: any) => {
  if (axios.isAxiosError(error) && error.response) {
    const message =
      error.response.data.error || error.response.data.message || error.message;
    toast.error("Error: " + message);
  } else {
    toast.error("An unexpected error occurred.");
  }
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  // username: Yup.string().required("Username is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^\d+$/, "Phone number is not valid")
    .required("Phone number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  role: Yup.string().required("Role is required"),
  isActive: Yup.boolean().required("Status is required"),
  // profilePic: Yup.mixed().required("A profile picture is required"),
  accessWarehouses: Yup.array()
    .of(Yup.string())
    .min(1, "At least one warehouse must be selected")
    .required("Warehouse access is required"),
});

export default function AddUser({ handleClose, setUsers }: AddUserProps) {
  const [warehouses, setWarehouses] = useState<WarehouseTypes[]>([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(null);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [warehouseNames, setWarehouseNames] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    // Handle "Select All" option
    if (value[value.length - 1] === "all") {
      if (selectAll) {
        // If already selected, unselect all
        setSelectAll(false);
        setPersonName([]);
        formik.setFieldValue("accessWarehouses", []);
      } else {
        // If not selected, select all
        setSelectAll(true);
        setPersonName(warehouseNames);
        const allWarehouseIds = warehouses.map((wh) => wh.id);
        formik.setFieldValue("accessWarehouses", allWarehouseIds);
      }
    } else {
      // Handle individual checkbox selection
      setSelectAll(false);
      const nextSelectedWarehouses =
        typeof value === "string" ? value.split(",") : value;
      setPersonName(nextSelectedWarehouses);
      const selectedWarehouseIds = nextSelectedWarehouses
        .map((name) => {
          const warehouse = warehouses.find((wh) => wh.name === name);
          return warehouse ? warehouse.id : null;
        })
        .filter((v): v is string => v !== null);
      formik.setFieldValue("accessWarehouses", selectedWarehouseIds);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const response = await axios.get(`${baseUrl}/warehouses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWarehouses(response.data);
        const warehouseNames = response.data.map(
          (warehouse: any) => warehouse.name
        );
        setWarehouseNames(warehouseNames);

        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          // If it's not an Error instance or you don't know the type, you can set a default error message
          setError("An error occurred while fetching the users.");
          setLoading(false);
        }
      }
    };

    fetchUsers();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      // username: "",
      email: "",
      phone: "",
      isActive: true,
      password: "",
      role: "",
      // profilePic: null,
      accessWarehouses: [],
    },

    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const { ...userData } = values;

        const response = await axios.post(`${baseUrl}/users`, userData);
        const addedUser = response.data.user as UserTypes;

        setUsers((prevUsers) => [...prevUsers, addedUser]);
        toast.success("User added successfully!");
        handleClose();
        formik.resetForm();
      } catch (error) {
        // handleError(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  if (loading) {
    return <div>Loading users...</div>;
  }
  const roles = ["admin", "user"];
  return (
    <div>
      {/* AddWarehouse */}
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            // marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoFocus
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={
                formik.touched.firstName ? formik.errors.firstName : ""
              }
              sx={{ flex: "1 0 45%" }}
            />
            <TextField
              margin="normal"
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName ? formik.errors.lastName : ""}
              sx={{ flex: "1 0 45%" }}
            />
            {/* <TextField
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username ? formik.errors.username : ""}
              sx={{ flex: "1 0 45%" }}
            /> */}
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
              sx={{ flex: "1 0 45%" }}
            />
            {/* <TextField
              margin="normal"
              fullWidth
              id="phone"
              label="Phone Number"
              name="phone"
              value={formik.values.phone}
              // onChange={formik.handleChange}
              onChange={handleNumberChange("phone")}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone ? formik.errors.phone : ""}
              sx={{ flex: "1 0 45%" }}
            /> */}

            <CustomPhoneInput
              formik={formik}
              name="phone"
              value={formik.values.phone}
              label="Phone Number"
            />
            {/* <TextField
              margin="normal"
              fullWidth
              id="password"
              name="password"
              label="Password *"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password ? formik.errors.password : ""}
              sx={{ flex: "1 0 45%" }}
            /> */}

            <TextField
              margin="normal"
              fullWidth
              id="password"
              name="password"
              label="Password *"
              type={showPassword ? "text" : "password"} // تغيير نوع الإدخال بناءً على حالة إظهار/إخفاء كلمة المرور
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password ? formik.errors.password : ""}
              sx={{ flex: "1 0 45%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControl fullWidth margin="normal" sx={{ flex: "1 0 45%" }}>
              <InputLabel id="role-label">Role *</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                name="role"
                label="Role *"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                <MenuItem value={roles[0]}>Admin</MenuItem>
                <MenuItem value={roles[1]}>User</MenuItem>
                {/* Add other roles as necessary */}
              </Select>
            </FormControl>

            {/* 
            <FormControl fullWidth margin="normal" sx={{ flex: "1 0 45%" }}>
              <InputLabel htmlFor="user-image">User Image</InputLabel>
              <input
                id="user-image"
                name="profilePic"
                type="file"
                // value={formik.values.profilePic}
                onChange={handleFileChange}
              />
            </FormControl> */}

            {/* <FormControl fullWidth margin="normal" sx={{ flex: "1 0 45%" }}>
              <input
                accept="image/*" // للسماح بملفات الصور فقط
                id="user-image"
                name="profilePic"
                type="file"
                style={{ display: "none" }} // إخفاء عنصر الإدخال
                onChange={handleFileChange}
              />
              <label htmlFor="user-image">
                <Button
                  component="span" // يسمح بإستخدام الزر كعنصر label
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  User Image
                </Button>
              </label>
            </FormControl> */}

            {/* <FormControl fullWidth margin="normal" sx={{ flex: "1 0 45%" }}>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  id="user-image"
                  name="profilePic"
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>
            </FormControl> */}

            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  name="isActive"
                />
              }
              label="Active User"
            />

            {/* <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="access-warehouses">
                Access Warehouses
              </InputLabel>
              <Select
                labelId="access-warehouses"
                id="access-warehouses"
                multiple
                value={formik.values.accessWarehouses}
                onChange={handleWarehouseAccessChange}
                renderValue={(selected) => {
                  // Check if all warehouses are selected
                  if (selected.length === warehouses.length) {
                    return "All Warehouses";
                  } else {
                    // Map through selected values and find the corresponding warehouse names
                    return selected
                      .map((id) => {
                        const warehouse = warehouses.find(
                          (wh) => wh.id === id
                        );
                        return warehouse ? warehouse.name : "Unknown"; // Replace 'Unknown' with appropriate fallback
                      })
                      .join(", ");
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                <MenuItem value="all">All Warehouses</MenuItem>
                {warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Access Warehouses
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                label="Access Warehouses"
                multiple
                // value={formik.values.accessWarehouses}
                value={selectAll ? warehouseNames : personName}
                onChange={handleChange}
                // input={<OutlinedInput label="Access Warehouses" />}
                // renderValue={(selected) => selected.join(", ")}
                renderValue={(selected) => {
                  if (selected.length === warehouseNames.length) {
                    return "All Warehouses";
                  }
                  return `${selected.length} selected`;
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value="all">
                  <Checkbox checked={selectAll} />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {warehouseNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={personName.includes(name) || selectAll}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
              color: (theme) => theme.palette.custom?.buttonColor,
              mt: 3,
              mb: 2,
            }}
            disabled={formik.isSubmitting}
          >
            Add User
          </Button>
        </Box>

        {/* <ToastContainer position="top-center" /> */}
      </Container>
    </div>
  );
}
