import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Navbar from "Components/Navbar/Navbar";
import Sidebar from "Components/Sidebar/Sidebar";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Theme, styled } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
// import { useTheme } from "@emotion/react";
import myTheme from "utils/theme/theme";

const drawerWidth = 240;

type UserDataType = {
  id: number;
  name: string;
  email: string;
  // other user fields
};

interface MainLayoutProps {
  userData?: UserDataType; // If userData is passed from parent component
  setUserData?: (userData: UserDataType | null) => void; // If setUserData is passed from parent component
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MainLayout({ userData, setUserData }: MainLayoutProps) {
  // const theme = useTheme();
  const [open, setOpen] = useState(true);

  // const [sidebarOpen, setSidebarOpen] = useState(true);

  const [mode, setMyMode] = React.useState<PaletteMode>(
    localStorage.getItem("currentMode") === null
      ? "light"
      : localStorage.getItem("currentMode") === ("light" as PaletteMode)
      ? "light"
      : "dark"
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let navigate = useNavigate();
  function logOut() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("user");
    // let decoded = jwtDecode(encodedToken);
    if (setUserData) {
      setUserData(null);
    }
    navigate("/login");
    // setUserData(null);
    // navigate("/login");
  }

  const theme = createTheme({
    palette: myTheme(mode),
  });

  const sidebarWidth = 240; // or the width you use for the sidebar
  const tableWidth = open ? `calc(98vw - ${sidebarWidth}px)` : "100vw";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="">
        <Navbar
          userData={userData}
          logOut={logOut}
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          drawerWidth={drawerWidth}
          setMyMode={setMyMode}
        />

        <Box sx={{ display: "flex", ml: "0px", mt: "20px", p: 2 }}>
          <Sidebar open={open} drawerWidth={drawerWidth} />
          <Box component="main" style={{ width: tableWidth }}>
            <DrawerHeader />
            {/* <Container component="main" maxWidth="md"></Container> */}
            <Outlet />
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
}
