// palette.ts
import { PaletteMode, PaletteOptions } from "@mui/material";
import { pink, teal } from "@mui/material/colors";

import { grey } from "@mui/material/colors";
import "@mui/material/styles";

// sx={{ bgcolor: theme.palette.custom?.sideIcon }}

//-----------------------------------------------------------------
// تقديرات للألوان استنادًا إلى الصورة
const lightBlue = "#d0eaff"; // للزر الرئيسي وخلفيات البطاقات
const alertRed = "#f44336"; // icons
// const backgroundGrey = "#f5f5f5"; // للخلفية العامة
// const textGrey = "#424242"; // للنصوص
const card = "#83C5FD4D"; // لخلفية البطاقات
const sidebarColor = "#EEF2F6";

//-----------------------------------------------------------------
//-------------------------------------
// sx={{
//   bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
//   color: (theme) => theme.palette.custom?.dialogColor,
// }}

// sx={{
//   bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
//   color: (theme) => theme.palette.custom?.dialogColor,
// }}
//-------------------------------------

declare module "@mui/material/styles" {
  interface Palette {
    custom?: PaletteOptions["custom"];
    // sideIcon?: PaletteOptions['custom'];
  }
  interface PaletteOptions {
    custom?: {
      activeLink?: string;
      sideIcon?: string;
      buttonColor?: string;
      buttonBgColor?: string;
      dialogBgColor?: string;
      dialogColor?: string;
      sidebarColor?: string;
      dropdownBgColor?: string;
      card?: string;
    };
  }
}

const myTheme = (mode: PaletteMode): PaletteOptions => ({
  mode: mode,
  ...(mode === "light"
    ? {
        // palette values for light mode

        // primary: blue,
        // divider: blue[200],
        // text: {
        //   primary: teal[900],
        //   secondary: teal[700],
        // },
        // testColor: {
        //   primary: pink[200],
        //   secondary: "#ffccbc",
        // },
        custom: {
          activeLink: "#83C5FD80",
          sideIcon: "#A91E1C",
          buttonColor: "white",
          buttonBgColor: "#A91E1C",
          dialogBgColor: "white",
          dialogColor: "black",
          sidebarColor: sidebarColor,
          dropdownBgColor: "white",
          card: card,
        },

        // palette: {
        //   MuiSelect: {
        //     main: "red",
        //   },
        //   primaryLight: {
        //     main: "#dbece2",
        //     contrastText: "#616161",
        //   },
        // },

        // sideIcon: {
        // },

        primary: {
          main: pink[600], // bg text
          contrastText: grey[800], // لون النص المتناقض
          light: "red",
          dark: pink[900],
          // activLink: grey[800],
        },

        // secondary: {
        //   main: purple[300], // تقدير للون خلفية البطاقات في الصورة 1
        //   light: 'red',
        //   // contrastText: purple[800], // لون النص المتناقض على البطاقات
        // },
        background: {
          default: grey[100], // خلفية الصفحة العامة
          // default: teal[50], // خلفية الصفحة العامة
          // paper: "#83C5FD4D", // خلفية العناصر مثل البطاقات
          // paper: grey[300],
        },

        text: {
          primary: grey[800], // لون النص الأساسي
          // secondary: pink[600], // لون النص الثانوي
        },

        error: {
          main: alertRed,
        },

        action: {
          active: pink[300], //icons
          // hover: alertRed,
          selected: teal[500],
          focus: lightBlue[500],

          // disabled:alertRed,

          // disabledBackground:deepPurple[500],
          // disabledOpacity: 0.38
          // focusOpacity: 0.12
          // activatedOpacity: 0.12
          // hoverOpacity: 0.04
          // selectedOpacity: 0.08
        },

        // components: {
        //   MuiPaper: {
        //     styleOverrides: {
        //       root: {
        //         backgroundColor: `${cardWhite} !important`,
        //       },
        //     },
        //   },
        // },

        // components: {
        //   MuiSelect: {
        //     styleOverrides: {
        //       // This will change the dropdown's background
        //       select: {
        //         backgroundColor: pink[900], // adjust colors as needed
        //       },
        //     },
        //   },

        // },
      }
    : // Dark
      {
        // palette values for dark mode
        custom: {
          activeLink: "#83C5FD80",

          // sideIcon: "#A91E1C",
          // buttonColor: "white",
          // buttonBgColor: "#A91E1C",
          // dialogBgColor: "white",
          // dialogColor: "black",
          sidebarColor: '#132337',
          // dropdownBgColor: "white",
          // card: card,
        },
        // primary: indigo,
        // divider: indigo[700],

        // primary: blueGrey,
        background: {
          default: "#0f1824", //body
          paper: '#132337', //card
          // paper: "#344767",
        },
        text: {
          primary: "#ffffff",
          secondary: pink[300],
        },
        //#83C5FD4D
        // testColor: {
        //   primary: pink[500],
        //   secondary: "#f48fb1",
        // },
      }),
});

export default myTheme;
