// import React from "react";
// // import "./ProtectedRoutes.scss";
// import { Navigate, useNavigate } from 'react-router-dom';

// export default function ProtectedRoutes(props) {
//   if (localStorage.getItem("userToken")) {
//     return props.children;
//   } else {
//     return <Navigate replace to="/login" />;
//   }
// }

// export function RedirectIfLoggedIn({ children }) {
//   if (localStorage.getItem("userToken")) {
//     return <Navigate to="/dashboard" />;
//   } else {
//     return children;
//   }
// }

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "utils/baseUrl";
import { toast } from "react-toastify";

function getCurrentUser() {
  try {
    const userStr = localStorage.getItem("user");
    if (!userStr) {
      // console.log("User string is null");
      return null;
    }

    const userObj = JSON.parse(userStr);
    if (!userObj.id) {
      toast.error("User ID is undefined");
      return null;
    }

    // console.log("User ID is:", userObj.id);
    return userObj.id;
  } catch (error) {
    toast.error("Error parsing user data", error);
    return null;
  }
}

export default function ProtectedRoutes({ children }) {
  const navigate = useNavigate();
  // const [isMounted, setIsMounted] = useState(true);
  var isMounted = true;

  useEffect(() => {
    let isMounted = true;
    const checkUserStatus = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) throw new Error("No token found");

        const currentUser = getCurrentUser();
        if (!currentUser) throw new Error("No user found");
        const response = await axios.get(
          `${baseUrl}/users/checkstatus/${currentUser}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.data.data.isActive && isMounted) {
          toast.error("Account is inactive. Logging out.");
          localStorage.removeItem("userToken");
          localStorage.removeItem("user");
          navigate("/login");
          // throw new Error("Account is inactive. Logging out.");
          // localStorage.removeItem("userToken");
          // localStorage.removeItem("user");
          // navigate("/login");
        }

        // if (currentUser) {
        // }
      } catch (error) {
        if (isMounted) {
          toast.error(
            error.response?.data.message ||
              "Failed to validate status, logging out."
          );

          localStorage.removeItem("userToken");
          localStorage.removeItem("user");
          navigate("/login");
        }
      }
    };

    checkUserStatus();
    return () => {
      isMounted = false;
    };
  }, [navigate, isMounted]);

  return children;
}

// ...

export function RedirectIfLoggedIn({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserStatusAndRedirect = async () => {
      const token = localStorage.getItem("userToken");
      if (token) {
        const currentUser = getCurrentUser();
        if (currentUser) {
          try {
            const response = await axios.get(
              `${baseUrl}/users/checkstatus/${currentUser}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.data.data.isActive) {
              navigate("/dashboard");
            } else {
              throw new Error("Inactive account, redirecting to login.");
            }
          } catch (error) {
            console.error("Error checking user status:", error);
            toast.error(error.response?.data.message);
            localStorage.removeItem("userToken");
            localStorage.removeItem("user");
            navigate("/login");
          }
        }
      }
    };

    checkUserStatusAndRedirect();
  }, [navigate]);

  return children;
}
