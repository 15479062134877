import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";

type Types = {
  handleClose: () => void;
  onUpdateSuccess: any;
  selectedCategory: any;
  open: boolean;
};

export default function UpdateVendorGroup({
  handleClose,
  onUpdateSuccess,
  selectedCategory,
  open,
}: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedCategory.name,
      // description: selectedCategory.description
    },
    // validationSchema,
    onSubmit: async (values) => {
      const submittedData = await sendData({
        endpoint: `vendor-groups/${selectedCategory.id}`,
        data: values,
        isJson: true,
        method: "put",
      });
      if (submittedData) {
        onUpdateSuccess(submittedData.vendorGroup);
        handleClose();
      }
    },
  });

  const errorToDisplay = sendError;
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        handleClose={handleClose}
        open={open}
        formTitle={"Update Vendor Group"}
        saveButtonLabel={"Update Vendor Group"}
        isSubmitting={formik.isSubmitting}
      />
    </>
  );
}
