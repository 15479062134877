import { styled, Theme, CSSObject, useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import GroupIcon from "@mui/icons-material/Group";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ConstructionIcon from "@mui/icons-material/Construction";
import CategoryIcon from "@mui/icons-material/Category";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NoTransferIcon from '@mui/icons-material/NoTransfer';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FireTruckIcon from '@mui/icons-material/FireTruck';

import {
  Box,
  Drawer as MuiDrawer,
  List,
  CssBaseline,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";

// import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
// import DraftsIcon from "@mui/icons-material/Drafts";
// import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { useState } from "react";

// import  brandLogo from "./../../assets/logo.png";

// const brandLogo = require('./../../assets/logo.png');

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface SidebarProps {
  open: boolean;
  drawerWidth: number;
}

interface DrawerProps {
  theme: Theme;
  open: boolean;
  drawerWidth: number;
}

type OpenSubMenus = {
  [key: number]: boolean;
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }: DrawerProps) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    "& .MuiDrawer-paper": openedMixin(theme, drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const list = [
  { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
  { text: "Warehouses", icon: <WarehouseIcon />, path: "/warehouses" },
  { text: "User Management", icon: <GroupIcon />, path: "/usersmanagement" },
  { text: "Supplies", icon: <PrecisionManufacturingIcon />, path: "/supplies" },
  { text: "Equipments", icon: <ConstructionIcon />, path: "/equipment" },
  { text: "Tags", icon: <LocalOfferIcon />, path: "/Tags" },
  { text: "Categories", icon: <CategoryIcon />, path: "/categories" },
  { text: "Transfers", icon: <NoTransferIcon />, path: "/transfers" },
  { text: "Vendors", icon: <StorefrontIcon />, path: "/vendorsgroup" },
  { text: "Purchases", icon: <ShoppingCartIcon />, path: "/purchases" },
  { text: "Load Order In", icon: <FireTruckIcon />, path: "/loadorderin" },
  {
    text: "Test",
    icon: <InboxIcon />,
    path: "/test",
    subItems: [
      { text: "Subitem 1", icon: <StarBorder />, path: "/test1" },
      { text: "Subitem 2", icon: <StarBorder />, path: "/test2" },
      { text: "Subitem 3", icon: <StarBorder />, path: "/test3" },
      // ... more subitems if needed
    ],
  },
];

export default function Sidebar({ open, drawerWidth }: SidebarProps) {
  // const [isCollapse, setIsCollapse] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState<OpenSubMenus>({});

  // const handleClick = () => {
  //   setIsCollapse(!isCollapse);
  // };

  const theme = useTheme();
  const navigate = useNavigate();
  let location = useLocation();

  const handleItemClick = (
    item: { path: string; subItems?: any[] },
    index: number
  ) => {
    if (!item.subItems) {
      navigate(item.path);
    } else {
      setOpenSubMenus((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    }
  };

  const handleSubItemClick = (path: string) => {
    navigate(path);
  };

  const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: 0,
    mr: theme.spacing(3),
    justifyContent: "center",
    color: theme.palette.custom?.sideIcon,
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer
        theme={theme}
        variant="permanent"
        open={open}
        drawerWidth={drawerWidth}
        PaperProps={{
          sx: {
            // bgcolor: theme.palette.primary.main,
            bgcolor: theme.palette.custom.sidebarColor,
          },
        }}
      >
        <DrawerHeader>
          {/* <img src={brandLogo} alt="" width={"60%"} /> */}
          <img
            // src={require("./../../assets/logo.png")}
            // src={require("https://res.cloudinary.com/dgufbbbat/image/upload/v1721878503/inventory_nestjs/logo_ociouh.png")}
            src="https://res.cloudinary.com/dgufbbbat/image/upload/v1721878503/inventory_nestjs/logo_ociouh.png"
            alt=""
            width={open ? "60%" : "60px"}
          />
        </DrawerHeader>
        <Divider />

        <List>
          {list.map((item, index) => {
            return (
              <>
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                    bgcolor:
                      location.pathname === item.path
                        ? theme.palette.custom?.activeLink
                        : null,
                  }}
                >
                  <Tooltip
                    title={open ? "" : item.text}
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: "1rem",
                          color: "white",
                          backgroundColor: "black",
                          // ml: 2,
                        },
                      },
                    }}
                  >
                    <ListItemButton
                      component="a"
                      // href={item.path}
                      href={`#${item.path}`}
                      rel="noopener noreferrer"
                      // onClick={() => {
                      //   navigate(item.path);
                      // }}
                      // onClick={() => handleItemClick(item, index)}
                      onClick={(e) => {
                        e.preventDefault();
                        handleItemClick(item, index);
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          // bgcolor: theme.palette.custom?.sideIcon,
                        }}
                      >
                        <CustomListItemIcon>{item.icon}</CustomListItemIcon>
                      </ListItemIcon>

                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />

                      {item.subItems &&
                        (openSubMenus[index] ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                  </Tooltip>
                </ListItem>

                {item.subItems && (
                  <Collapse
                    in={openSubMenus[index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem, subIndex) => (
                        <Tooltip
                          title={open ? "" : item.text}
                          placement="right"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: "1rem",
                                color: "white",
                                backgroundColor: "black",
                                // ml: 2,
                              },
                            },
                          }}
                        >
                          <ListItemButton
                            key={subIndex}
                            sx={{
                              pl: 4,
                              bgcolor:
                                location.pathname === subItem.path
                                  ? theme.palette.action.selected
                                  : // ?theme.palette.testColor.primary
                                    null,
                            }}
                            onClick={() => handleSubItemClick(subItem.path)}
                          >
                            <ListItemIcon>{subItem.icon}</ListItemIcon>
                            <ListItemText primary={subItem.text} />
                          </ListItemButton>
                        </Tooltip>
                      ))}
                    </List>
                  </Collapse>
                )}
              </>
            );
          })}
        </List>
      </Drawer>

      {/* <Box component="main" sx={{  p: 3 }}>
        <DrawerHeader />
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus
          dolor purus non enim praesent elementum facilisis leo vel. Risus at
          ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum
          quisque non tellus. Convallis convallis tellus id interdum velit
          laoreet id donec ultrices. Odio morbi quis commodo odio aenean sed
          adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
          integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate
          eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo
          quis imperdiet massa tincidunt. Cras tincidunt lobortis feugiat
          vivamus at augue. At augue eget arcu dictum varius duis at consectetur
          lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa sapien
          faucibus et molestie ac.
        </Typography>

      </Box> */}
    </Box>
  );
}
