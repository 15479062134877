// export const AllowToNumberOnly = (setterFunction: any) => (event: any) => {
//   const value = event.target.value;
//   const onlyNums = value.replace(/[^0-9]/g, "");
//   setterFunction(onlyNums);
// };

// export const AllowToNumberOnlyy = (setterFunction: any, fieldName: string) => (event: any) => {
//   const value = event.target.value;
//   const onlyNums = value.replace(/[^0-9]/g, "");
//   setterFunction(fieldName, onlyNums);
// };

export const AllowToNumberOnly =
  (setterFunction: any, fieldName: string, decimal: boolean = false) =>
  (event: any) => {
    let value = event.target.value;
    if (decimal) {
      // السماح بالأرقام العشرية
      value = value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
    } else {
      // السماح بالأرقام الصحيحة فقط
      value = value.replace(/[^0-9]/g, "");
    }
    setterFunction(fieldName, value);
  };
