// SupplyFormFields.tsx
import React, { useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import useSendData from "Components/Axios/SendData";
import { AllowToNumberOnly } from "Components/AllowToNumberOnly/AllowToNumberOnly";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import CustomTextField from "Components/CustomTextField/CustomTextField";
import ReusableDialog from "Components/Dialog/Dialog";
import { NumericFormat } from "react-number-format";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseUrl } from "utils/baseUrl";
import { toast } from "react-toastify";

// Define the props for the new component
export interface SupplyFormFieldsProps {
  formik: any;
  isSubmitting: boolean;
  handleClose: () => void;
  open?: any;
  formTitle: string;
  saveButtonLabel: string;

  warehouses?: any;
  selectedSupply?: any;
  isEdit?: any;
  setSupplies?: any;
  supplies?: any;
  suppliesData: any;
  equipmentData: any;
  warehouseData?: any;
}

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = `${today.getMonth() + 1}`.padStart(2, "0");
  const day = `${today.getDate()}`.padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const FormFields: React.FC<SupplyFormFieldsProps> = ({
  formik,
  isSubmitting,
  handleClose,
  open,
  formTitle,
  saveButtonLabel,
  warehouses,
  selectedSupply,
  isEdit,
  setSupplies,
  supplies,

  suppliesData,
  equipmentData,
  warehouseData,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendData, error, isLoading: sendLoading } = useSendData();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSaving, setIsSaving] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [options, setOptions] = React.useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loading = open && options.length === 0;
  // const [date, setDate] = useState(getCurrentDate());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSupplies, setSelectedSupplies] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  // const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [supplierSearchResults, setSupplierSearchResults] = useState<any[]>([]);
  const [equipmentSearchResults, setEquipmentSearchResults] = useState<any[]>(
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorr, setError] = useState<string | null>(null);
  const { values, setFieldValue } = formik;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
    AllowToNumberOnly(
      (name: string, value: any) => {
        formik.setFieldValue(name, value);
      },
      fieldName,
      decimal
    );
  //-----------------------

  // const onSupplySelect = (event: any, value: any) => {
  //   if (value) {
  //     setSelectedSupplyy(value);
  //     formik.setFieldValue("supplies", value.id);
  //     handleAddSupply(value.id, value.name, value.quantity);
  //   }
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSupplySelect = (event: any, values: any[]) => {
    // Use a Set to track unique IDs
    const selectedIds = new Set(
      formik.values.supplies.map((item: any) => item.supplyId)
    );
    const newValues = values.filter((value: any) => {
      const isDuplicate = selectedIds.has(value.id);
      selectedIds.add(value.id); // Add new ID to the Set
      return !isDuplicate; // Keep non-duplicates
    });

    // Map over newValues to create a list of selected supplies
    const newSelectedSupplies = newValues.map((value: any) => ({
      supplyId: value.id,
      name: value.name,
      quantity: 1,
      stock: value.quantity,
    }));

    // Update state and Formik values with new unique list
    setSelectedSupplies(newSelectedSupplies);
    formik.setFieldValue("supplies", newSelectedSupplies);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleQuantityChange = (supplyId: any, newQuantity: any) => {
  //   setSelectedSupplies((prevSupplies) =>
  //     prevSupplies.map((supply) =>
  //       supply.supplyId === supplyId
  //         ? { ...supply, quantity: newQuantity }
  //         : supply
  //     )
  //   );
  // };
  //-----------------------
  // const handleAddEquipment = (equipment: any) => {
  //   const newEquipment = {
  //     equipmentId: equipment.id,
  //     name: equipment.name,
  //     quantity: 1,
  //     stock: equipment.quantity, // استخدم الخاصية quantity
  //   };

  //   setSelectedEquipments([...selectedEquipments, newEquipment]);
  //   formik.setFieldValue("equipments", [
  //     ...formik.values.equipments,
  //     newEquipment,
  //   ]);
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onEquipmentSelect = (event: any, values: any[]) => {
    const newSelectedEquipments = values.map((value) => ({
      equipmentId: value.id,
      name: value.name,
      quantity: 1, // Default quantity
      stock: value.quantity, // Assuming 'quantity' represents the stock
    }));

    // Update local state for the UI
    setSelectedEquipments(newSelectedEquipments);

    // Update Formik's state for form submission
    formik.setFieldValue("equipments", newSelectedEquipments);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleQuantityEquipmentChange = (
    equipmentId: string,
    newQuantity: number
  ) => {
    setSelectedEquipments((currentEquipments) =>
      currentEquipments.map((equipment) =>
        equipment.equipmentId === equipmentId
          ? { ...equipment, quantity: newQuantity }
          : equipment
      )
    );
  };

  const handleSearch = async (event: any) => {
    const searchQuery = event.target.value;

    if (searchQuery && searchQuery.length > 0) {
      try {
        const token = localStorage.getItem("userToken");

        const warehouseId = formik.values.fromWarehouse;

        if (!warehouseId) {
          toast.error("Please select a 'From Warehouse' before searching.");
          return;
        }

        const supplierResponse = await axios.get(`${baseUrl}/supplies`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { search: searchQuery, warehouseId: warehouseId },
        });

        const equipmentResponse = await axios.get(`${baseUrl}/equipments`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { search: searchQuery, warehouseId: warehouseId },
        });

        if (Array.isArray(supplierResponse.data.supplies)) {
          setSupplierSearchResults(supplierResponse.data.supplies);
        } else {
          setSupplierSearchResults([]);
        }

        if (Array.isArray(equipmentResponse.data.equipments)) {
          setEquipmentSearchResults(equipmentResponse.data.equipments);
        } else {
          setEquipmentSearchResults([]);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setError("Error fetching search results: " + error);
      }
    } else {
      setSupplierSearchResults([]);
      setEquipmentSearchResults([]);
    }
  };

  const combinedSearchResults = [
    ...supplierSearchResults.map((supply) => ({
      id: supply.id,
      name: supply.name,
      quantity: supply.quantity,
      type: "Supply",
    })),
    ...equipmentSearchResults.map((equipment) => ({
      id: equipment.id,
      name: equipment.name,
      quantity: equipment.quantity,
      type: "Equipment",
    })),
  ];

  const handleSelectSearchResult = (result: any) => {
    if (result) {
      const newItem = {
        id: result.id,
        name: result.name,
        quantity: 1,
        stock: result.quantity,
        type: result.type,
      };

      if (result.type === "Supply") {
        setFieldValue("supplies", [...values.supplies, newItem]);
      } else if (result.type === "Equipment") {
        setFieldValue("equipments", [...values.equipments, newItem]);
      }
    }
  };

  const handleDelete = (id: any) => {
    setFieldValue(
      "supplies",
      values.supplies.filter((item: any) => item.id !== id)
    );
    setFieldValue(
      "equipments",
      values.equipments.filter((item: any) => item.id !== id)
    );
  };

  React.useEffect(() => {
    if (!formik.values.date) {
      formik.setFieldValue("date", getCurrentDate());
    }
  }, [formik]);

  return (
    <>
      <ReusableDialog
        formTitle={formTitle}
        formik={formik}
        isSubmitting={isSubmitting}
        saveButtonLabel={saveButtonLabel}
        // isEdit={isEdit}
        onClose={handleClose}
        open={open}
        dialogMaxWidth="md"
        // handleSubmit={handleSubmit}
        showButton={false}
      >
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: "15px",
            }}
          >
            <TextField
              fullWidth
              name="date"
              label="Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              // value={formik.values.date}
              // onChange={formik.handleChange}
              value={formik.values.date} // This will now be controlled by Formik
              onChange={formik.handleChange}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            />

            <FormControl
              fullWidth
              margin="dense"
              error={
                formik.touched.fromWarehouse &&
                Boolean(formik.errors.fromWarehouse)
              }
            >
              <InputLabel id="from-warehouse-select-label">
                From Warehouse
              </InputLabel>
              <Select
                labelId="from-warehouse-select-label"
                id="from-warehouse-select"
                label="From Warehouse"
                // onBlur={formik.handleBlur}
                value={formik.values.fromWarehouse || ""}
                onChange={(event) => {
                  formik.setFieldValue("fromWarehouse", event.target.value);
                }}
                onBlur={() => formik.setFieldTouched("fromWarehouse")}
              >
                {warehouses.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: "15px",
            }}
          >
            <FormControl fullWidth margin="dense">
              <InputLabel id="to-warehouse-select-label">
                To Warehouse
              </InputLabel>
              <Select
                labelId="to-warehouse-select-label"
                id="to-warehouse-select"
                label="To Warehouse"
                value={formik.values.toWarehouse || ""}
                onChange={(event) => {
                  formik.setFieldValue("toWarehouse", event.target.value);
                }}
              >
                {/* {warehouses.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))} */}
                {warehouses
                  .filter(
                    (option: any) => option.id !== formik.values.fromWarehouse
                  )
                  .map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {equipmentData && suppliesData && (
              <Autocomplete
                fullWidth
                options={combinedSearchResults}
                getOptionLabel={(option: any) => option.name || ""}
                onInputChange={(event, newInputValue) => {
                  handleSearch({ target: { value: newInputValue } });
                }}
                onChange={(event: any, newValue: any) =>
                  handleSelectSearchResult(newValue)
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Search Equipments and Supplies"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            )}
          </Box>

          <>
            <>
              {/* {suppliesData && suppliesData.supply && (
                <Autocomplete
                  id="supplies-autocomplete"
                  options={suppliesData.supply || []}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    suppliesData.supply.find(
                      (supply) => supply.id === formik.values.supplies
                    ) || null
                  }
                  onChange={onSupplySelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose supplies"
                      variant="outlined"
                      error={
                        formik.touched.supplies &&
                        Boolean(formik.errors.supplies)
                      }
                      helperText={
                        formik.touched.supplies ? formik.errors.supplies : ""
                      }
                    />
                  )}
                  fullWidth
                />
              )} */}

              {/* {equipmentData && equipmentData.equipment && (
                <Autocomplete
                  multiple
                  id="equipment-autocomplete"
                  options={equipmentData.equipment || []}
                  getOptionLabel={(option) => (option ? option.name || "" : "")}
                  value={
                    formik.values.equipments.map((equipmentId: any) =>
                      equipmentData.equipment.find(
                        (equipment) => equipment.id === equipmentId
                      )
                    ) || []
                  }
                  onChange={onEquipmentSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose equipments"
                      variant="outlined"
                      error={
                        formik.touched.equipments &&
                        Boolean(formik.errors.equipments)
                      }
                      helperText={
                        formik.touched.equipments
                          ? formik.errors.equipments
                          : ""
                      }
                    />
                  )}
                  fullWidth
                />
              )} */}

              {/* {equipmentdata && (
              <autocomplete
                multiple
                id="equipments-autocomplete"
                options={equipmentdata || []}
                // getoptionlabel={(option) => option.name}
                getoptionlabel={(option) => option.name || ""}
                isoptionequaltovalue={(option, value) =>
                  option.id === value.equipmentid
                }
                // value={equipmentdata.equipment.filter((equipment) =>
                //   formik.values.equipments.includes(equipment.id)
                // )}
                value={formik.values.equipments}
                onchange={onequipmentselect}
                // onchange={(event, values) => {
                //   const newselectedequipments = values.map((value) => ({
                //     equipmentid: value.id,
                //     name: value.name,
                //     quantity: 1,
                //     stock: value.quantity,
                //   }));
                //   setselectedequipments(newselectedequipments);
                //   formik.setfieldvalue("equipments", newselectedequipments);
                // }}
                filterselectedoptions
                renderinput={(params) => (
                  <textfield
                    {...params}
                    style={{ minwidth: 500 }}
                    label="choose equipments"
                    variant="outlined"
                    error={
                      formik.touched.equipments &&
                      boolean(formik.errors.equipments)
                    }
                    helpertext={
                      formik.touched.equipments ? formik.errors.equipments : ""
                    }
                    fullwidth
                  />
                )}
              />
            )} */}

              {/* {suppliesdata && (
              <autocomplete
                multiple
                id="supplies-autocomplete"
                options={suppliesdata || []}
                getoptionlabel={(option) => option.name}
                isoptionequaltovalue={(option, value) =>
                  option.id === value.supplyid
                }
                // defaultvalue={suppliesdata.supply || []}
                // value={suppliesdata.supply.filter((supply) =>
                //   formik.values.supplies.includes(supply.id)
                // )}
                // onchange={onsupplyselect}
                value={formik.values.supplies}
                onchange={onsupplyselect}
                filterselectedoptions
                renderinput={(params) => (
                  <textfield
                    {...params}
                    style={{ minwidth: 500 }}
                    label="choose supplies"
                    placeholder="select supplies"
                  />
                )}
              />
            )} */}

              {/* <customtextfield
                name="quantity"
                label="quantity"
                // type="number"
                onblur={formik.handleblur}
                value={formik.values.quantity}
                // onchange={handlenumberchange("quantity")}
                onchange={(event: any) => {
                  const newquantity = event.target.value; // get the new quantity from the input field
                  if (selectedsupplyy && newquantity !== "") {
                    handlequantitychange(
                      selectedsupplyy.id,
                      number(newquantity)
                    ); // call the handler with both the supply id and the new quantity
                  }
                }}
                error={
                  formik.touched.quantity && boolean(formik.errors.quantity)
                }
                helpertext={
                  formik.touched.quantity ? formik.errors.quantity : ""
                }
              /> */}
            </>

            {/* Table to display selected equipments */}
            {/* <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Equipment</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Stock</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formik.values.equipments.map(
                    (equipment: any, index: number) => (
                      <TableRow key={equipment.equipmentId}>
                        <TableCell>{equipment.name}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={equipment.quantity}
                            onChange={(event) => {
                              const newQuantity = Number(event.target.value);
                              // Create a copy of the equipment list and update the quantity of the specific item
                              const updatedEquipments =
                                formik.values.equipments.map(
                                  (item: any, idx: number) =>
                                    idx === index
                                      ? { ...item, quantity: newQuantity }
                                      : item
                                );
                              // Update the formik state with the new list
                              formik.setFieldValue(
                                "equipments",
                                updatedEquipments
                              );
                            }}
                            inputProps={{
                              min: 1,
                              max: equipment.stock,
                            }}
                          />
                        </TableCell>
                        <TableCell>{equipment.stock}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box> */}

            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 580 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>Stock</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...values.supplies, ...values.equipments].map(
                      (row: any, index: any) => {
                        const handleQuantityChange = (event: any, id: any) => {
                          const quantity = parseFloat(event.target.value);
                          if (!isNaN(quantity)) {
                            // تحقق مما إذا كان العنصر موجود في قائمة الأجهزة أو الإمدادات
                            const isEquipment = values.equipments.some(
                              (item: any) => item.id === id
                            );
                            const updatedItems = isEquipment
                              ? values.equipments.map((item: any) =>
                                  item.id === id ? { ...item, quantity } : item
                                )
                              : values.supplies.map((item: any) =>
                                  item.id === id ? { ...item, quantity } : item
                                );

                            if (isEquipment) {
                              setFieldValue("equipments", updatedItems);
                            } else {
                              setFieldValue("supplies", updatedItems);
                            }
                          }
                        };

                        return (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell>{row.stock}</TableCell>
                            <TableCell sx={{ width: 140 }}>
                              <NumericFormat
                                value={row.quantity}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  handleQuantityChange(
                                    { target: { value: floatValue } },
                                    row.id
                                  );
                                }}
                                thousandSeparator
                                customInput={TextField}
                                decimalScale={3}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleDelete(row.id)}
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>

            {isEdit === true ? (
              <FormControl fullWidth margin="dense">
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  label="Status"
                >
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="InProgress">In Progress</MenuItem>
                </Select>
              </FormControl>
            ) : null}

            <CustomTextField
              name="note"
              label="Note"
              multiline
              rows={4}
              value={formik.values.note}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.note && Boolean(formik.errors.note)}
              // helperText={formik.touched.note ? formik.errors.note : ""}
              helperText={
                formik.touched.note && typeof formik.errors.note === "string"
                  ? formik.errors.note
                  : ""
              }
            />
          </>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {saveButtonLabel}
          </Button>
        </>
      </ReusableDialog>
    </>
  );
};

export default FormFields;
