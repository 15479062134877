import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FetchData from "Components/Axios/FetchData";

type SupplyDetailsProps = {
  handleClose: () => void;
  open: boolean;
  transferId: any;
};

const LoadOrderInDetails: React.FC<SupplyDetailsProps> = ({
  open,
  handleClose,
  transferId,
}) => {
  const [transferData, setTransferData] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const {
    data: fetchedData,
    error: fetchError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading,
  } = FetchData(`load-order-in/${transferId}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supplies, setSupplies] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (fetchedData) {
      setTransferData(fetchedData);
      setQrCodeUrl(fetchedData.qrcode);
    } else if (fetchError) {
      setError(`Error loading Load Order In details`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData, fetchError]);

  if (!transferData) return <div>Loading...</div>;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent
        sx={{
          bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
          color: (theme) => theme.palette.custom?.dialogColor,
        }}
      >
        <IconButton onClick={handleClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom textAlign={"center"}>
          Load Order In Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {/* <strong>ID:</strong> {transferData.transfer.id} */}
            </Typography>
            {qrCodeUrl && (
              <img
                src={qrCodeUrl}
                alt="QR Code"
                style={{ width: 200, height: 200 }}
              />
            )}
            <Typography variant="subtitle1">
              <strong>Status:</strong> {transferData.status}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Date:</strong>{" "}
              {new Date(transferData.date).toLocaleString()}
            </Typography>
            <Typography variant="subtitle1">
              <strong>From Warehouse:</strong>{" "}
              {transferData.fromWarehouse?.name}
            </Typography>
            <Typography variant="subtitle1">
              <strong>To Warehouse:</strong> {transferData.toWarehouse?.name}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Note:</strong> {transferData.note}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Supplies:</Typography>
            <List dense>
              {transferData.supplies.map((supply: any) => (
                <ListItem key={supply.id}>
                  <ListItemText
                    primary={supply.name}
                    secondary={`Quantity: ${supply.quantity}`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Equipments:</Typography>
            <List dense>
              {transferData.equipments.map((equipment: any) => (
                <ListItem key={equipment.id}>
                  <ListItemText
                    primary={equipment.name}
                    secondary={`Quantity: ${equipment.quantity}, Stock: ${equipment.stock}`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default LoadOrderInDetails;
