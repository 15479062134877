import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "react-slick";
import FetchData from "Components/Axios/FetchData";

interface Category {
  id: string;
  name: string;
}

interface Tag {
  id: string;
  name: string;
}

interface Supply {
  id: string;
  description: string;
  quantity: number;
  price: number;
  minValue: number;
  images: { secure_url: string }[];
  categories: Category[];
  tags: Tag[];
  name: string;
  updatedAt?: string;
}

type SupplyDetailsProps = {
  handleClose: () => void;
  open: boolean;
  equipment: Supply;
};

const EquipmentDetails: React.FC<SupplyDetailsProps> = ({
  equipment,
  open,
  handleClose,
}) => {
  const item_name = equipment;
  const {
    data: fetchedData,
    error: fetchError,
    isLoading,
  } = FetchData(`equipments/${equipment.id}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [equipments, setEquipments] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (fetchedData && fetchedData.equipment) {
      setEquipments(fetchedData.equipment);
    } else if (fetchError) {
      setError(`Error loading ${item_name.name} `);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData, fetchError]);

  if (isLoading) return <div>Loading...</div>;
  if (fetchError) return <div>Error loading data</div>;
  if (!fetchedData || !fetchedData.equipment)
    return <div>No data available</div>;

  const equipmentData = fetchedData.equipment;

  const settings = {
    customPaging: function (i: any) {
      return (
        <Box>
          {equipmentData.images && equipmentData.images[i] && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              src={equipmentData.images[i].secure_url}
              style={{ width: "50px", height: "50px", marginTop: "50px" }}
            />
          )}
        </Box>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const totalValue = equipmentData.price * equipmentData.quantity;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent
        sx={{
          bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
          color: (theme) => theme.palette.custom?.dialogColor,
        }}
      >
        <IconButton onClick={handleClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom textAlign={"center"}>
          Equipment Details
        </Typography>
        <Grid container spacing={3} p="15px">
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Basic Info
            </Typography>
            <Typography variant="subtitle1">
              <strong>Name:</strong> {equipmentData.name}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Description:</strong> {equipmentData.description}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Price Per:</strong> ${equipmentData.price}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Stock:</strong> {equipmentData.quantity} unit
            </Typography>
            <Typography variant="subtitle1">
              <strong>Low Alert:</strong> {equipmentData.minValue}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Total Price:</strong> ${totalValue.toFixed(2)}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Updated At:</strong>{" "}
              {new Date(equipmentData.updatedAt).toLocaleString()}
            </Typography>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <strong>----------------------------------</strong>{" "}
              </Typography>
              {equipmentData.categories &&
                equipmentData.categories.length > 0 && (
                  <Typography variant="subtitle1">
                    <strong>Categories:</strong>{" "}
                    {equipmentData.categories
                      .map((c: any) => c.name)
                      .join(", ")}
                  </Typography>
                )}
              <Typography variant="subtitle1">
                <strong>----------------------------------</strong>{" "}
              </Typography>
              {equipmentData.tags && equipmentData.tags.length > 0 && (
                <Typography variant="subtitle1">
                  <strong>Tags:</strong>{" "}
                  {equipmentData.tags.map((t: any) => t.name).join(", ")}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            {equipmentData.images && (
              <Slider {...settings}>
                {equipmentData.images.map((img: any, idx: any) => (
                  <div key={idx}>
                    <img
                      src={img.secure_url}
                      alt={`Supply ${idx}`}
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "contain",
                        marginBottom: "50px",
                      }}
                    />
                  </div>
                ))}
              </Slider>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EquipmentDetails;
