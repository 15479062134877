import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";

type Types = {
  handleClose: () => void;
  onAddSupply: any;
  open: any;
  id: any;
};

export default function AddVendor({
  handleClose,
  onAddSupply,
  id,
  open,
}: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: "",
      vendorName: "",
      email: "",
      phone: "",
      notes: "",
      group: Number(id),
    },
    // validationSchema,
    onSubmit: async (values) => {
      console.log("Form submission values:", values);
      const submittedData = await sendData({
        endpoint: "vendors",
        data: values,
        isJson: true,
        method: "post",
      });
      if (submittedData) {
        onAddSupply(submittedData.vendor);
        handleClose();
      }
    },
  });

  const errorToDisplay = sendError;
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        formTitle={"Add New Vendor"}
        saveButtonLabel={"Create Vendor"}
      />
    </>
  );
}
