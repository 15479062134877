/**
 * Appends form data from a values object.
 * @param {Object} values - The values to append.
 * @returns {FormData}
 */
export function FormDataHelper(values: any) {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item instanceof Blob ? item : String(item));
      });
    } else {
      formData.append(key, value instanceof Blob ? value : String(value));
    }
  });
  return formData;
}
