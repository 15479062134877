import React, { useState } from "react";
import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { baseUrl } from "../../utils/baseUrl";
import { Box, Button, TextField, Typography } from "@mui/material";

export default function ForgotPassword() {
  // const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const formikForgotPassword = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email").required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseUrl}/auth/forgotPassword`,
          values
        );
        if (response.data.message === "Password reset code sent to your email") {
          setIsCodeSent(true);
          toast.success("Verification code sent to your email.");
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          setErrMsg(error.response.data.error);
          toast.error(error.response.data.error);
        } else {
          toast.error("An unknown error occurred.");
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  // const formikResetPassword = useFormik({
  //   initialValues: {
  //     code: "",
  //     // email: formikForgotPassword.values.email,
  //     // newPassword: "",
  //     // confirmPassword: "",
  //   },
  //   // validationSchema: Yup.object({
  //   //   code: Yup.string().required("Required"),
  //   //   email: Yup.string().email("Invalid email").required("Required"),
  //   //   newPassword: Yup.string().required("Required"),
  //   //   confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required("Required"),
  //   // }),
  //   onSubmit: async (values) => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.post(`${baseUrl}/auth/verifyResetCode`, {
  //         code: values.code,
  //         // email: values.email,
  //         // newPassword: values.newPassword,
  //         // confirmPassword: values.confirmPassword,
  //       });
  //       if (response.data.message === "success") {
  //         toast.success("success code");
  //         navigate("/login");
  //       }
  //     } catch (error) {
  //       if (axios.isAxiosError(error) && error.response) {
  //         setErrMsg(error.response.data.message);
  //         toast.error(error.response.data.error);
  //       } else {
  //         toast.error("An unknown error occurred.");
  //       }
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   },
  // });

  // let mySchema = Yup.object({
  //   email: Yup.string().email("Invalid email").required("Required"),
  // });

  // async function forgotPassword(val: any) {
  //   let { data } = await axios.post(`${baseUrl}/auth/forgotPassword`, val);
  //   // console.log(data);
  //   if (data.statusMsg === "success") {
  //     setflageData(true);
  //   }
  // }

  // let formik = useFormik({
  //   initialValues: {
  //     email: "",
  //   },
  //   validationSchema: mySchema,
  //   onSubmit: (values) => {
  //     // console.log(values);
  //     forgotPassword(values);
  //   },
  // });

  // async function resetPassword(val: any) {
  //   try {
  //     let response = await axios.post(`${baseUrl}/auth/verifyResetCode`, val);
  //     let data = response.data; // TypeScript knows `data` exists here
  //     if (data.status === "Success") {
  //       Navigate("/resetPassword");
  //     }
  //     console.log(data);
  //   } catch (err) {
  //     // Assuming err is of type AxiosError for better type safety
  //     if (axios.isAxiosError(err) && err.response) {
  //       seterrMsg(err.response.data.message);
  //     } else {
  //       console.log(err);
  //       seterrMsg("An unknown error occurred");
  //     }
  //   }
  // }

  // let formik1 = useFormik({
  //   initialValues: {
  //     resetCode: "",
  //   },
  //   // validationSchema: mySchema,
  //   onSubmit: (values) => {
  //     // console.log(values);
  //     resetPassword(values);
  //   },
  // });

  return (
    <Box className="login-page">
      <Box className="formParent">
        <Box sx={{ py: 4 }}>
          {isCodeSent ? (
            <>
              {/* <form onSubmit={formikResetPassword.handleSubmit}>
              <TextField
                fullWidth
                label="Reset Code"
                name="code"
                value={formikResetPassword.values.code}
                onChange={formikResetPassword.handleChange}
                onBlur={formikResetPassword.handleBlur}
                error={formikResetPassword.touched.code && Boolean(formikResetPassword.errors.code)}
                helperText={formikResetPassword.touched.code && formikResetPassword.errors.code}
                margin="normal"
              />
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {isLoading ? "Verifying..." : "Verify Code"}
              </Button>
            </form> */}

              <Typography variant="h3" gutterBottom>
                Check Your Email
              </Typography>
            </>
          ) : (
            <form onSubmit={formikForgotPassword.handleSubmit}>
              <Typography variant="h3" gutterBottom>
                Forgot Your Password?
              </Typography>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formikForgotPassword.values.email}
                onChange={formikForgotPassword.handleChange}
                onBlur={formikForgotPassword.handleBlur}
                error={
                  formikForgotPassword.touched.email &&
                  Boolean(formikForgotPassword.errors.email)
                }
                helperText={
                  formikForgotPassword.touched.email &&
                  formikForgotPassword.errors.email
                }
                margin="normal"
              />
              <Button
                type="submit"
                variant="contained"
                // color="primary"
                fullWidth
                disabled={isLoading}
              >
                {isLoading ? "Sending..." : "Send"}
              </Button>
            </form>
          )}
        </Box>
      </Box>
      {errMsg && <Typography color="error">{errMsg}</Typography>}
    </Box>
  );
}
