import { Box, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "utils/baseUrl";
// import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import { IconButton } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { GridRenderCellParams } from "@mui/x-data-grid";

import ConfirmDelete from "Components/Axios/ConfirmDelete";
import ReusableTable from "Components/Table/Table";
import { toast } from "react-toastify";
import AddVendor from "./AddVendor/AddVendor";
import UpdateVendor from "./UpdateVendor/UpdateVendor";

interface Item {
  id: string;
  companyName?: string;
  vendorName?: string;
  email?: string;
  phone?: number;
  notes?: string;
  price?: number;
}

interface CategoryDetails {
  id: string;
  name: string;
  description: string;
  suppliers: Item[];
}

const initialCategoryDetails: CategoryDetails = {
  id: "",
  name: "",
  description: "",
  suppliers: [],
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export default function Vendors() {
  const { id } = useParams<{ id: string }>();
  const [categoryDetails, setCategoryDetails] =
    useState<CategoryDetails | null>(initialCategoryDetails);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState("");

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const [selectedSupply, setSelectedSupply] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    let token = localStorage.getItem("userToken");
    axios
      .get(`${baseUrl}/vendor-groups/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCategoryDetails({
          ...response.data.vendorGroup,
          suppliers: response.data.vendorGroup.vendors || [],
        });
      })
      .catch((error: any) => {
        setError(error.response?.data?.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleAddCategorySuccess = (newSupplier: any) => {
    setCategoryDetails((prevDetails) => ({
      ...prevDetails,
      suppliers: prevDetails?.suppliers
        ? [...prevDetails.suppliers, newSupplier]
        : [newSupplier],
    }));
  };

  const handleDeleteClick = (supplierId: string) => {
    if (categoryDetails) {
      const supplierToDelete = categoryDetails.suppliers.find(
        (s) => Number(s.id) === Number(supplierId)
      );
      if (supplierToDelete) {
        setSelectedSupply(supplierToDelete);
        setDeleteDialogOpen(true);
      } else {
        toast.error("Vendor not found.");
      }
    }
  };

  const handleEdit = (supplierId: string) => {
    const supplierToEdit = categoryDetails?.suppliers.find(
      (s) => Number(s.id) === Number(supplierId)
    );

    if (supplierToEdit) {
      setSelectedSupply(supplierToEdit);
      setIsEditModalOpen(true);
    } else {
      toast.error("Vendor not found.");
    }
  };

  const handleUpdateVendorSuccess = (updatedSupplier: any) => {
    setCategoryDetails((prevDetails) => {
      const updatedSuppliers = prevDetails?.suppliers.map((supplier) => {
        if (supplier.id === updatedSupplier.id) {
          return updatedSupplier;
        }
        return supplier;
      });
      return { ...prevDetails, suppliers: updatedSuppliers };
    });
    setIsEditModalOpen(false); // Close the edit modal dialog
  };

  const columns: GridColDef[] = [
    { field: "companyName", headerName: "Company Name", flex: 1 },
    { field: "vendorName", headerName: "Contact Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    { field: "notes", headerName: "Notes", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <IconButton onClick={() => handleDeleteClick(String(params.id))}>
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={() => handleEdit(params.id.toString())}>
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const filteredRows =
    categoryDetails?.suppliers
      ?.filter((supplier) => {
        const searchLower = searchText.toLowerCase();
        return (
          supplier.companyName.toLowerCase().includes(searchLower) ||
          supplier.vendorName.toLowerCase().includes(searchLower) ||
          supplier.email.toLowerCase().includes(searchLower)
        );
      })
      .map((supplier) => ({
        id: supplier.id,
        companyName: supplier.companyName,
        vendorName: supplier.vendorName,
        email: supplier.email,
        phone: supplier.phone,
        notes: supplier.notes,
      })) || [];

  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Typography gutterBottom variant="h4" fontWeight="medium">
        Vendors for {categoryDetails?.name}
      </Typography>

      <ReusableTable
        searchText={searchText}
        onSearchChange={handleSearchChange}
        onAddButtonClick={handleOpenAddDialog}
        rows={filteredRows}
        columns={columns}
        loading={loading}
        addButtonName="Add New Vendor"
      />

      <AddVendor
        open={openAddDialog}
        handleClose={handleCloseAddDialog}
        onAddSupply={handleAddCategorySuccess}
        id={id}
      />

      {isEditModalOpen && selectedSupply && (
        <UpdateVendor
          selectedCategory={selectedSupply}
          handleClose={() => setIsEditModalOpen(false)}
          open={isEditModalOpen}
          onUpdateSuccess={handleUpdateVendorSuccess}
        />
      )}

      {deleteDialogOpen && selectedSupply && (
        <ConfirmDelete
          itemName={selectedSupply.companyName}
          endpoint={`vendors/${selectedSupply.id}`}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDeleted={() => {
            setCategoryDetails((prevDetails) => ({
              ...prevDetails,
              suppliers: prevDetails.suppliers.filter(
                (s) => s.id !== selectedSupply.id
              ),
            }));
            setDeleteDialogOpen(false);
          }}
        />
      )}
    </>
  );
}
