import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { Types } from "../types/Supplies-Types";

import validationSchema from "../validationSchema";

import FetchData from "Components/Axios/FetchData";
import useSendData from "Components/Axios/SendData";
import FormFields from "../FormFields";
// import Loading from "Components/ErrorAndLoading/Loading";

type AddSupplyProps = {
  handleClose: () => void;
  setWarehouse: any;
  onTransferAdded: any;
  open: any;
  // setSupplies: React.Dispatch<React.SetStateAction<Types[]>>;
};

export default function AddLoadOrderIn({
  handleClose,
  setWarehouse,
  onTransferAdded,
  open,
}: // open,
AddSupplyProps) {
  const {
    sendData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isSuccess,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: sendError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: sendLoading,
  } = useSendData();

  // const { data: categoriesData } = FetchData("categories");

  const { data: warehouseData, error: warehouseError } =
    FetchData("warehouses");
  const [warehouses, setWarehouses] = useState([]);

  const { data: suppliesData, error: suppliesError } = FetchData("supplies"); // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supplies, setSupplies] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [equipments, setEquipments] = useState([]);
  const { data: equipmentData, error: equipmentError } =
    FetchData("equipments");
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (warehouseData && Array.isArray(warehouseData)) {
      setWarehouses(warehouseData);
    } else if (warehouseData) {
      toast.error("Error fetching warehouses.");
    }

    if (
      suppliesData &&
      suppliesData.supplies &&
      Array.isArray(suppliesData.supplies)
    ) {
      setSupplies(suppliesData.supplies);
    } else {
      setSupplies([]);
      if (suppliesData) {
        toast.error("Error fetching supplies.");
      }
    }

    if (
      equipmentData &&
      equipmentData.equipments &&
      Array.isArray(equipmentData.equipments)
    ) {
      setEquipments(equipmentData.equipments);
    } else {
      setEquipments([]);
      if (equipmentData) {
        toast.error("Error fetching equipments.");
      }
    }
  }, [
    warehouseData,
    suppliesData,
    equipmentData,
    warehouseError,
    suppliesError,
    equipmentError,
  ]);

  // }, [warehouseData, suppliesData, equipmentData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: "",
      fromWarehouse: "",
      toWarehouse: "",
      // supplies: null,
      // equipments: null,
      supplies: [], // This should be an array now
      equipments: [],
      // quantity: "",
      // status: "",
      note: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // const formattedSupplies = values.supplies.map((supply: any) => ({
      //   id: supply.id,
      //   name: supply.name,
      //   quantity: supply.quantity,
      //   stock: supply.stock,
      // }));

      // const formattedEquipments = values.equipments.map((equipment: any) => ({
      //   name: equipment.name,
      //   quantity: equipment.quantity,
      //   stock: equipment.stock,
      // }));

      const suppliesWithFullData = values.supplies.map((supply) => {
        const fullSupplyData = suppliesData.supplies.find(
          (s: any) => s.name === supply.name
        );
        if (!fullSupplyData) {
          toast.error(`Supply "${supply.name}" not found.`);
          throw new Error(`Supply "${supply.name}" not found.`);
        }
        return {
          id: fullSupplyData.id,
          name: fullSupplyData.name,
          description: fullSupplyData.description,
          price: fullSupplyData.price,
          quantity: supply.quantity,
        };
      });

      const equipmentsWithFullData = values.equipments.map((equipment) => {
        const fullEquipmentData = equipmentData.equipments.find(
          (e: any) => e.name === equipment.name
        );
        if (!fullEquipmentData) {
          toast.error(`Equipment "${equipment.name}" not found.`);
          throw new Error(`Equipment "${equipment.name}" not found.`);
        }
        return {
          id: fullEquipmentData.id,
          name: fullEquipmentData.name,
          quantity: equipment.quantity,
        };
      });

      const formattedData = {
        date: values.date,
        fromWarehouse: values.fromWarehouse,
        toWarehouse: values.toWarehouse,
        // supplies: values.supplies.map((supply) => ({
        //   id: supply.id,
        //   name: supply.name,
        //   quantity: supply.quantity,
        // })),
        // supplies: suppliesWithIds,
        supplies: suppliesWithFullData,
        // equipments: values.equipments.map((equipment) => ({
        //   name: equipment.name,
        //   quantity: equipment.quantity,
        // })),
        equipments: equipmentsWithFullData,
        // status: values.status,
        note: values.note,
      };

      // const jsonData = {
      //   // quantity: values.quantity,
      //   date: values.date,
      //   fromWarehouse: values.fromWarehouse,
      //   toWarehouse: values.toWarehouse,
      //   // supplies: values.supplies ? [values.supplies] : [],
      //   // equipments: values.equipments ? [values.equipments] : [],
      //   supplies: formattedSupplies,
      //   equipments: formattedEquipments,
      //   status: values.status,
      //   note: values.note,
      // };

      const submittedData = await sendData({
        endpoint: "load-order-in",
        data: formattedData,
        isJson: true,
        method: "post",
      });

      if (submittedData) {
        setWarehouse((prevTransfers: any) => [...prevTransfers, submittedData]);
        onTransferAdded();
        resetForm();
        handleClose();
      } else {
        toast.error(submittedData.message || "An error occurred"); // Show error message
      }
    },
  });

  // if (!Array.isArray(suppliesData)) {
  //   console.error("suppliesData is not an array:", suppliesData);
  //   return;
  // }

  // const errorToDisplay = fetchError || sendError;
  return (
    <>
      <>
        {/* <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={isLoading || sendLoading} /> */}
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        formTitle={"Add New Load Order In"}
        saveButtonLabel={"Create Load Order In"}
        suppliesData={suppliesData}
        equipmentData={equipmentData}
        warehouseData={warehouseData}
        warehouses={warehouses}
      />
    </>
  );
}
