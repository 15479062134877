import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";

const useStyles = makeStyles({
  formField: {
    marginBottom: "16px",
  },
});

interface Category {
  id?: string;
  name: string;
  description: string;
  // imgCover: string;
}

interface AddCategoryDialogProps {
  open: boolean;
  onClose: () => void;
  onAddCategorySuccess: any;
}

const initialValues: Omit<Category, "id"> = {
  name: "",
  description: "",
  // imgCover: "",
};

const CategorySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // description: Yup.string().required("Description is required"),
});

export default function AddCategory({
  open,
  onClose,
  onAddCategorySuccess,
}: AddCategoryDialogProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File | null>(null);
  const classes = useStyles();

  const handleAddCategory = async (values: Omit<Category, "id">) => {
    const data = {
      name: values.name,
      description: values.description,
    };

    try {
      const token = localStorage.getItem("userToken");
      if (!token) throw new Error("Token not found");

      const response = await axios.post(`${baseUrl}/categories`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      onAddCategorySuccess(response.data.category);
      onClose();
      toast.success("Category added successfully");
    } catch (error) {
      toast.error("Error adding Category ");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
          color: (theme) => theme.palette.custom?.dialogColor,
        }}
      >
        <DialogTitle>
          Add New Category
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              // bgcolor: (theme) => theme.palette.custom?.button,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={CategorySchema}
          onSubmit={(values, actions) => {
            handleAddCategory(values);
            actions.setSubmitting(false);
            setFile(null);
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <DialogContent>
                <Field
                  component={TextField}
                  name="name"
                  label="Category Name"
                  fullWidth
                  variant="outlined"
                  className={classes.formField}
                />
                <Field
                  component={TextField}
                  name="description"
                  label="Description"
                  fullWidth
                  variant="outlined"
                  className={classes.formField}
                  sx={{
                    mt: "20px",
                  }}
                />

                {/* <input
                 accept="image/*"
                 id="imgCover-file"
                 type="file"
                 hidden
                 onChange={(event) => {
                   const newFile = event.target.files
                     ? event.target.files[0]
                     : null;
                   setFile(newFile);
                 }}
               />
               <label htmlFor="imgCover-file">
                 <Button
                   variant="contained"
                   component="span"
                   startIcon={<CloudUploadIcon />}
                 >
                   Upload Image Cover
                 </Button>
               </label> */}
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  color="primary"
                  variant="contained"
                  sx={{
                    bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                    color: (theme) => theme.palette.custom?.buttonColor,
                  }}
                >
                  {isSubmitting ? <CircularProgress size={24} /> : "Add"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}
