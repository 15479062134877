import React, { useEffect, useState } from "react";
import { Button, FormControl, FormHelperText } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

type MultipleImageUploaderProps = {
  initialImages?: File[];
  // onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onImagesChange?: (images: File[]) => void;
  multiple?: boolean;
  formik?: any;
};

const MultipleImageUploader: React.FC<MultipleImageUploaderProps> = ({
  initialImages,
  onImagesChange,
  multiple = false,
  formik,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [files, setFiles] = useState([]);
  // const [selectedFiles, setSelectedFiles] = useState<File[]>(initialImages);

  useEffect(() => {
    if (initialImages) {
      setFiles(initialImages);
    }
  }, [initialImages]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const filesArray: File[] = Array.from(files);
      setFiles(filesArray);
      if (onImagesChange) {
        onImagesChange(filesArray);
      }
    }
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files) {
  //     const filesArray = Array.from(event.target.files);
  //     setSelectedFiles(filesArray);
  //     onImagesChange(filesArray); //
  //   }
  // };

  return (
    <>
      <FormControl
        fullWidth
        margin="normal"
        // margin="dense"
        sx={{ flex: "1 0 45%" }}
        error={formik.touched.images && Boolean(formik.errors.images)}
      >
        <input
          accept="image/*"
          // id="image-uploader"
          id="multiple-image-uploader"
          type="file"
          multiple={multiple}
          onChange={handleImageChange}
          // multiple
          style={{ display: "none" }}
        />
        <label htmlFor="multiple-image-uploader">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            {multiple ? "Upload Images" : "Upload Image"}
            {/* {files.map((file, index) => (
              <div key={index}>{file.name}</div>
            ))} */}
            {/* Upload Images */}
          </Button>
        </label>
        {formik.touched.images && formik.errors.images && (
          <FormHelperText>{formik.errors.images}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default MultipleImageUploader;
