import React from "react";
import { Box, Typography } from "@mui/material";

type ErrorDisplayProps = {
  error: string | null;
};

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ error }) => {
  if (!error) return null;

  return (
    <Box>
      <Typography color="error">Error: {error}</Typography>
    </Box>
  );
};

export default ErrorDisplay;
