import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import AddTag from "./AddTag/AddTag";
import DeleteTag from "./DeleteTag/DeleteTag";
import UpdateTag from "./UpdateTag/UpdateTag";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "utils/baseUrl";
import ButtonAdd from "Components/ButtonAdd/ButtonAdd";

interface Tag {
  id: string;
  name: string;
  // description: string;
  // imgCover: string;
}

export default function Tags() {
  const [tags, setTags] = useState<Tag[]>([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${baseUrl}/tags`);
        setTags(response.data);
      } catch (error) {
        toast.error("Error fetching tags");
      }
    };

    if (openEditDialog === false) {
      fetchTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleOpenEditDialog = (tag: Tag) => {
    setSelectedTag(tag);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const handleOpenDeleteDialog = (tag: Tag) => {
    setSelectedTag(tag);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const onDeleteSuccess = (deletedTagId: string) => {
    setTags((currentTags) =>
      currentTags.filter((tag) => tag.id !== deletedTagId)
    );
    setOpenDeleteDialog(false);
  };

  const onUpdateSuccess = (updatedTag: any) => {
    if (!updatedTag) {
      // console.error("Updated tag is undefined.");
      return;
    }

    setTags((prevTags) => {
      return prevTags.map((tag) =>
        tag.id === updatedTag.id ? updatedTag : tag
      );
    });

    setOpenEditDialog(false);
  };

  const handleCategoryClick = (id: any) => {
    navigate(`/tag/${id}`);
  };

  const handleAddCategorySuccess = (newCategory: any) => {
    setTags([...tags, newCategory]);
  };

  return (
    <div>
      <Box sx={{ p: 2 }}>
        {/* Search and Add Button */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <ButtonAdd onClick={handleOpenAddDialog} name="Add New Tag" />
        </Box>

        {/* Display Categories */}
        <Grid
          container
          spacing={2}
          sx={{
            minWidth: "63vw",
            "& > .MuiGrid-item": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: "300px",
            },
          }}
        >
          {tags
            .filter((tag) =>
              tag.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((tag) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={tag.id}>
                <Card
                  sx={{
                    bgcolor: (theme) => theme.palette.custom?.card,
                  }}
                >
                  {/* <CardMedia
                    component="img"
                    height="140"
                    image={tag.imgCover}
                    alt={tag.name}
                  /> */}
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {tag.name}
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                      {tag.description}
                    </Typography> */}
                  </CardContent>
                  <Grid container justifyContent="flex-end">
                    <IconButton onClick={() => handleCategoryClick(tag.id)}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenEditDialog(tag)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(tag)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>

      <AddTag
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        onAddCategorySuccess={handleAddCategorySuccess}
        // onAdd={handleAddTag}
      />

      <DeleteTag
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        tag={selectedTag}
        onDeleteSuccess={onDeleteSuccess}
      />

      <UpdateTag
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        tag={selectedTag}
        onUpdateSuccess={onUpdateSuccess}
      />
    </div>
  );
}
