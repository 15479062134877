// import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
// import { Types } from "./../types/Supplies-Types";
// import { useParams } from "react-router-dom";
import FetchData from "Components/Axios/FetchData";
import FormFields from "../FormFields";
import validationSchema from "../validationSchema";
// import { Box, Button, Typography } from "@mui/material";
import useSendData from "Components/Axios/SendData";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import Loading from "Components/ErrorAndLoading/Loading";

interface EditSupplyProps {
  handleClose: () => void;
  setWarehous?: any;
  onTransferAdded?: any;
  transfer?: any;
  transferId?: any;
  open: any;
  // onUpdateSuccess?: (updatedSupply: any) => void;
}

const EditLoadOrderIn = ({
  handleClose,
  onTransferAdded,
  setWarehous,
  transfer,
  transferId,
  open,
}: // onUpdateSuccess,
// openEditDialog,
EditSupplyProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: categoriesData,
    error: categoriesError,
    isLoading: categoriesLoading,
  } = FetchData("categories");
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: tagsData,
    error: tagsError,
    isLoading: tagsLoading,
  } = FetchData("tags");
  const { data: warehouseData } = FetchData("warehouses");
  const [warehouses, setWarehouses] = useState([]);

  const { data: suppliesData } = FetchData("supplies");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supplies, setSupplies] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [equipments, setEquipments] = useState([]);
  const { data: equipmentData } = FetchData("equipments");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSupplies, setSelectedSupplies] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedEquipments, setSelectedEquipments] = useState([]);

  useEffect(() => {
    setWarehouses(warehouseData || []);
    setSupplies(suppliesData?.supply || []);
    setEquipments(equipmentData?.equipment || []);
  }, [warehouseData, suppliesData, equipmentData]);

  const formik = useFormik({
    initialValues: {
      ...transfer,
      // date: transfer?.date || "",
      fromWarehouse: transfer?.fromWarehouse || "",
      toWarehouse: transfer?.toWarehouse || "",
      supplies: transfer?.supplies || [],
      equipments: transfer?.equipments || [],
      status: transfer?.status || "",
      note: transfer?.note || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const submittedData = await sendData({
        endpoint: `load-order-in/${transferId}`,
        data: values,
        isJson: true,
        method: "put",
      });

      if (submittedData) {
        setWarehous((prevTransfers: any) =>
          prevTransfers.map((transfer: any) =>
            transfer.id === submittedData.id ? submittedData : transfer
          )
        );
        onTransferAdded();

        handleClose();
      } else {
        toast.error(submittedData.message || "An error occurred");
      }
    },
  });

  useEffect(() => {
    if (transfer && Array.isArray(transfer)) {
      const transferToEdit = transfer.find((t) => t.id === transferId);
      // console.log("============supplies ==============");
      // console.log(transferToEdit.supplies);
      // console.log("====================================");
      if (transferToEdit) {
        const date = transferToEdit.date
          ? new Date(transferToEdit.date).toISOString().split("T")[0]
          : "";
        formik.setValues({
          ...formik.values,
          date: date,
          fromWarehouse: transferToEdit.fromWarehouse?.id || "",
          toWarehouse: transferToEdit.toWarehouse?.id || "",
          supplies:
            transferToEdit.supplies?.map((s: any) => ({
              id: s.id,
              name: s.name,
              quantity: s.quantity,
              stock: s.stock,
            })) || [],
          equipments:
            transferToEdit.equipments?.map((e: any) => ({
              name: e.name,
              quantity: e.quantity,
              stock: e.stock,
            })) || [],
          status: transferToEdit.status || "",
          note: transferToEdit.note || "",
        });

        setSelectedSupplies(
          transferToEdit.supplies.map((supply: any) => ({
            supplyId: supply.id,
            name: supply.name,
            quantity: supply.quantity, // This should be the actual quantity, not '1'
            stock: supply.stock,
          }))
        );

        setSelectedEquipments(
          transferToEdit.equipments.map((equipment: any) => ({
            equipmentId: equipment.id,
            name: equipment.name,
            quantity: equipment.quantity, // This should be the actual quantity, not '1'
            stock: equipment.stock,
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfer]);

  return (
    <>
      <>
        <ErrorDisplay error={categoriesError || tagsError || sendError} />
        <Loading isLoading={categoriesLoading || tagsLoading || sendLoading} />
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        isEdit={true}
        formTitle="Edit Load Order In"
        saveButtonLabel="Update Load Order In"
        warehouses={warehouses}
        suppliesData={suppliesData}
        equipmentData={equipmentData}
        // warehouseData={warehouseData}
      />
    </>
  );
};

export default EditLoadOrderIn;
