import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";

type Types = {
  handleClose: () => void;
  onUpdateSuccess: any;
  selectedCategory: any;
  open: any;
};

export default function UpdateVendor({
  handleClose,
  onUpdateSuccess,
  selectedCategory,
  open,
}: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: selectedCategory.companyName,
      vendorName: selectedCategory.vendorName,
      email: selectedCategory.email,
      phone: selectedCategory.phone,
      notes: selectedCategory.notes,
      group: selectedCategory.group.id,
    },
    // validationSchema,
    onSubmit: async (values) => {
      const submittedData = await sendData({
        endpoint: `vendors/${selectedCategory.id}`,
        data: { ...values, group: Number(values.group) },
        isJson: true,
        method: "put",
      });
      if (submittedData) {
        onUpdateSuccess(submittedData.vendor);
        handleClose();
      }
    },
  });

  const errorToDisplay = sendError;
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        formTitle={"Update Vendor"}
        saveButtonLabel={"Update Vendor"}
      />
    </>
  );
}
