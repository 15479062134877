import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { baseUrl } from "utils/baseUrl";

interface UpdateCategoryProps {
  open: boolean;
  onClose: () => void;
  category: any;
  onUpdateSuccess: any;
}

const UpdateCategorySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // description: Yup.string().required("Description is required"),
  // imgCover: Yup.mixed().nullable(),
});

const UpdateCategory: React.FC<UpdateCategoryProps> = ({
  open,
  onClose,
  category,
  onUpdateSuccess,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState(null);
  // const [preview, setPreview] = useState<string | null>(null);

  // const initialValues = {
  //   name: category?.name || "",
  //   description: category?.description || "",
  //   // imgCover: category?.imgCover || "",
  // };

  // useEffect(() => {
  //   if (!file) {
  //     setPreview(category?.imgCover || "");
  //     return;
  //   }
  //   const filePreview = URL.createObjectURL(file);
  //   setPreview(filePreview);
  //   return () => URL.revokeObjectURL(filePreview);
  // }, [file, category?.imgCover]);

  // Logging for debugging
  useEffect(() => {
    // console.log("Selected category:", category);
  }, [category]);

  const formik = useFormik({
    initialValues: {
      name: category?.name || "",
      description: category?.description || "",
      // imgCover: category?.imgCover || "",
    },
    validationSchema: UpdateCategorySchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        description: values.description,
      };
      const token = localStorage.getItem("userToken");
      if (!token) throw new Error("Token not found");

      try {
        const response = await axios.put(
          `${baseUrl}/categories/${category?.id}`,
          data,
          {
            headers: { Authorization: `Bearer ${token}`,},
          }
        );
        onUpdateSuccess(response.data.category);
        toast.success("Category updated successfully");
        onClose();
      } catch (error) {
        toast.error("Error updating category");
      }
    },
  });

  // Handle file selection
  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const newFile = event.currentTarget.files
  //     ? event.currentTarget.files[0]
  //     : null;
  //   setFile(newFile);
  // };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
          color: (theme) => theme.palette.custom?.dialogColor,
        }}
      >
        {/* <DialogTitle>Edit Category</DialogTitle> */}
        <DialogTitle>
          Edit Category
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Category Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && typeof formik.errors.name === "string"
                  ? formik.errors.name
                  : ""
              }
            />
            <TextField
              sx={{
                mt: 3,
                mb: 3,
              }}
              fullWidth
              id="description"
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description &&
                typeof formik.errors.description === "string"
                  ? formik.errors.description
                  : ""
              }
            />

            {/* {preview && (
             <img
               src={preview}
               alt="Preview"
               style={{ maxWidth: "100%", marginBottom: "10px" }}
             />
           )} */}

            {/* <input
             accept="image/*"
             id="imgCover-file"
             name="imgCover"
             type="file"
             hidden
             onChange={handleFileChange}
           />
      
           <label htmlFor="imgCover-file">
             <Button
               sx={{
                 mt: 8,
               }}
               variant="contained"
               component="span"
               startIcon={<CloudUploadIcon />}
             >
               Upload Image Cover
             </Button>
           </label> */}
          </DialogContent>

          {/* <Button onClick={onClose}>Cancel</Button> */}
          {/* <Button type="submit" color="primary" disabled={formik.isSubmitting}>
            Save
          </Button> */}

          <DialogActions>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting}
              sx={{
                bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                color: (theme) => theme.palette.custom?.buttonColor,
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default UpdateCategory;
